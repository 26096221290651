var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { staticStyle: { width: "100%" }, attrs: { gutter: [24, 24] } },
        [
          _vm.addAuthority
            ? _c(
                "a-col",
                {
                  attrs: {
                    xxl: 24 / _vm.span.xxl,
                    xl: 24 / _vm.span.xl,
                    lg: 24 / _vm.span.lg,
                    md: 24 / _vm.span.md,
                    sm: 24 / _vm.span.sm,
                    xs: 24 / _vm.span.xs
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("addJeepayCard")
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "jeepay-card-add",
                      style: { height: _vm.height + "px" }
                    },
                    [
                      _c("img", {
                        style: {
                          width: _vm.height / 3 + "px",
                          height: _vm.height / 3 + "px"
                        },
                        attrs: {
                          src: require("@/assets/svg/add-icon.svg"),
                          alt: "add-icon"
                        }
                      }),
                      _c("div", { staticClass: "jeepay-card-add-text" }, [
                        _vm._v(" 新建" + _vm._s(_vm.name) + " ")
                      ])
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._l(_vm.cardList, function(item, key) {
            return _c(
              "a-col",
              {
                key: key,
                attrs: {
                  xxl: 24 / _vm.span.xxl,
                  xl: 24 / _vm.span.xl,
                  lg: 24 / _vm.span.lg,
                  md: 24 / _vm.span.md,
                  sm: 24 / _vm.span.sm,
                  xs: 24 / _vm.span.xs
                }
              },
              [
                _vm._t("cardContentSlot", null, { record: item }),
                _vm._t("cardOpSlot", null, { record: item })
              ],
              2
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }